@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.lexical h1 {
  @apply block font-sans text-[64px] font-semibold leading-[1.3] tracking-normal text-inherit antialiased;
}

.lexical h2 {
  @apply block font-sans text-3xl font-semibold leading-snug tracking-normal text-inherit antialiased;
}

.lexical p {
  @apply block font-sans text-base font-normal leading-relaxed text-inherit antialiased;
}

.lexical ul {
  @apply list-inside list-disc;
}

.lexical ol {
  @apply list-inside list-decimal;
}

.lexical li {
  @apply font-sans text-base font-normal leading-relaxed text-inherit antialiased;
}

.lexical blockquote {
  @apply block font-sans text-xl font-normal leading-relaxed text-inherit antialiased;
}

.lexical code {
  @apply rounded-md bg-gray-900/[0.1] px-1 py-0.5 text-sm font-normal text-gray-900;
}

.custom-slider .slick-slide {
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  margin-top: 5px;
  margin-bottom: 5px;
}

.custom-dots {
  position: relative;
  bottom: 30px; /* Смещение точек ниже */
}

.custom-dots li {
  display: inline-block;
  margin: 0 4px; /* Промежуток между точками */
}

.custom-dots li button {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.custom-dots li.slick-active button {
  background: #000; /* Цвет активной точки */
}

/* .slick-dots.custom-dots {
  padding-bottom: 10px;
} */

/* .slick-dots.custom-dots li {
  margin: 0 3px;
} */

/* .slick-dots.custom-dots li button:before {
  font-size: 10px;
  color: black;
  opacity: 0.3;
}

.slick-dots.custom-dots li.slick-active button:before {
  color: black;
} */

/* .custom-slider .slick-list {
  /* margin-right: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
} */

/* @layer base {
  .bg-black-custom{
    background-color: #3575ad;
  }
  .bg-black-custom2{
    background-color: #e72ac6;
  }

  .btn-custom {

  }

  .custom-size1{
    width: 20px !important;
    height: 20px !important;
  }
} */

html {
  zoom: 1; /* Устанавливаем масштабирование страницы на 100% */
  /* background-color: #f5f5f5; */
}

.glowing-button {
  @apply relative inline-flex items-center justify-center p-4 text-white font-medium transition duration-500 ease-out;

  background: linear-gradient(45deg, #ff6ec4, #7873f5, #00c9ff, #92fe9d);
  background-size: 400% 400%;
  animation: gradientBG 6s ease infinite;
  border-radius: 9999px;
  box-shadow:
    0 0 10px rgba(255, 255, 255, 0.6),
    0 0 20px rgba(255, 255, 255, 0.6),
    0 0 30px rgba(255, 255, 255, 0.6),
    0 0 40px rgba(255, 255, 255, 0.6);
  overflow: hidden;
}

.glowing-button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent,
    transparent
  );
  background-size: 400% 400%;
  animation: whiteStripe 6s ease infinite;
  transform: rotate(45deg);
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes whiteStripe {
  0%,
  25%,
  50%,
  75% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

@keyframes lineAnimation {
  0% {
    transform: translateX(-100%) translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(100%) translateY(-100%);
    opacity: 0;
  }
}

.animate-line {
  content: "";
  display: block;
  position: absolute;
  width: 200%;
  height: 2px;
  background: white;
  transform: translateX(-100%) translateY(100%);
  opacity: 0;
  animation: lineAnimation 1.5s linear infinite;
}
